// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-enviado-js": () => import("./../../../src/pages/contato-enviado.js" /* webpackChunkName: "component---src-pages-contato-enviado-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-contato-nao-enviado-js": () => import("./../../../src/pages/contato-nao-enviado.js" /* webpackChunkName: "component---src-pages-contato-nao-enviado-js" */),
  "component---src-pages-cursos-e-minicursos-homeopatia-para-veterinarios-js": () => import("./../../../src/pages/cursos-e-minicursos/homeopatia-para-veterinarios.js" /* webpackChunkName: "component---src-pages-cursos-e-minicursos-homeopatia-para-veterinarios-js" */),
  "component---src-pages-cursos-e-minicursos-homeopatia-veterinaria-clinica-avancada-js": () => import("./../../../src/pages/cursos-e-minicursos/homeopatia-veterinaria-clinica-avancada.js" /* webpackChunkName: "component---src-pages-cursos-e-minicursos-homeopatia-veterinaria-clinica-avancada-js" */),
  "component---src-pages-cursos-e-minicursos-index-js": () => import("./../../../src/pages/cursos-e-minicursos/index.js" /* webpackChunkName: "component---src-pages-cursos-e-minicursos-index-js" */),
  "component---src-pages-cursos-e-minicursos-matricula-enviada-js": () => import("./../../../src/pages/cursos-e-minicursos/matricula-enviada.js" /* webpackChunkName: "component---src-pages-cursos-e-minicursos-matricula-enviada-js" */),
  "component---src-pages-homeopatia-a-ciencia-do-terceiro-milenio-js": () => import("./../../../src/pages/homeopatia-a-ciencia-do-terceiro-milenio.js" /* webpackChunkName: "component---src-pages-homeopatia-a-ciencia-do-terceiro-milenio-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-profissionais-js": () => import("./../../../src/pages/profissionais.js" /* webpackChunkName: "component---src-pages-profissionais-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */),
  "component---src-pages-teste-js": () => import("./../../../src/pages/teste.js" /* webpackChunkName: "component---src-pages-teste-js" */)
}

